/**
* Generated automatically at built-time (2024-12-10T10:57:26.412Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkueche-edelstahl-ratgeber",templateKey: "sites/102-02881b0f-1a5d-485e-8f45-1edb08521b5e"};