/**
* Generated automatically at built-time (2024-12-10T10:57:26.147Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-nordic-line-outdoorkueche",templateKey: "sites/102-32baafe6-61ff-4232-ba5c-2d6bace7cb38"};